







import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  computed: {},
  data: () => ({}),
  methods: {},
})
export default class Home extends Vue {}
