var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about"},[_c('h1',[_vm._v("This is an about page")]),_c('pre',[_vm._v(_vm._s(Object.assign.apply(
      Object, [ {} ].concat( this.$route.hash
        .replace("#", "")
        .split("&")
        .map(function (x) {
            var _obj;

            return (( _obj = {}, _obj[x.split("=")[0]] = x.split("=")[1], _obj ));
}) )
    )["access_token"]))]),_c('pre',{on:{"load":_vm.store}},[_vm._v(_vm._s(Object.assign.apply(
      Object, [ {} ].concat( this.$route.hash
        .replace("#", "")
        .split("&")
        .map(function (x) {
            var _obj;

            return (( _obj = {}, _obj[x.split("=")[0]] = x.split("=")[1], _obj ));
}) )
    )))])])}
var staticRenderFns = []

export { render, staticRenderFns }